import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";

// TODO: Replace the following with your app's Firebase project configuration
// See: https://support.google.com/firebase/answer/7015592
// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAibsJm6-tHnrqVXFcjEKxqTo88Ll5-HDY",
    authDomain: "web-potfolio.firebaseapp.com",
    databaseURL: "https://web-potfolio.firebaseio.com",
    projectId: "web-potfolio",
    storageBucket: "web-potfolio.appspot.com",
    messagingSenderId: "855319080631",
    appId: "1:855319080631:web:a1d0e687ea9d7c52db6732"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(app);
export const Functions = getFunctions(app);