import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route, MemoryRouter } from "react-router-dom";

import Home from "./pages/Home"

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home/>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
