import '../App.css';
import '../css/main.css';
import '../css/style.css';
import '../css/responsive.css';
import '../css/animate.css';
import '../css/bootstrap.css';
import '../css/font-awesome.css';
import backgroundVideo from '../vid/city-video5.mp4';
import scrollDown from '../img/arrow-down-navigation.gif';
import logo from '../img/logo-design-3.png';
import logo_black from '../img/logo-design-3-black.png'
import alum from '../img/Alumni.png'
import resume from '../files/LeeMartins Resume.pdf'
import headshot from '../img/swagboy0.jpg'
import React, {useState} from 'react';
import { db, Functions } from '../firebase'
import { collection, addDoc } from "firebase/firestore"; 
import ReCAPTCHA from 'react-google-recaptcha';
import { getFunctions, httpsCallable } from "firebase/functions";


function Home() {

  const [contactName, setContactName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactMessage, setContactMessage] = useState("");
  const [verifyResonse, setVerifyResponse] = useState("");



  const handleRecaptchaVerify = async (response) => {

    const verify = httpsCallable(Functions, 'submitForm');


    // Implement your logic after reCAPTCHA verification
    const result = await verify(response);
        console.log("awaiting createdb result")
  
        // Set the response state with the response from the function
        setVerifyResponse(result);
        console.log(result)
    console.log('reCAPTCHA verified!', response);
    //handleSubmit()
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const docRef = await addDoc(collection(db, "mail"), {
        to: "mobeel5356@gmail.com",
        message: {
          subject: "Contact Form Submission: " + contactName,
          text: "This is the plaintext section of the email body.",
          html: `You have received a new message. <br> Here are the details:<br> Name: ${contactName} <br> Server: 0.0.0.0 <br> Email: ${contactEmail} <br> Message: <br> ${contactMessage}`,
        },
      });
      console.log("Document written with ID: ", docRef.id);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  

  };


  return (
    <div>
      <div >
        <header className="header" id="header" >
          {/* header-start */}
          <video className="myVideo" preload="metadata" autoPlay muted loop id="myVideo" playsInline>
            <source src={backgroundVideo} type="video/mp4" />
            Your browser does not support HTML5 video.
          </video>

          <div className="midcontainer">
            <figure className="animated fadeInDown delay-07s">
              <a href="#"><img className="logo" src={logo} alt="" /></a>
            </figure>
            <h1 className="animated fadeInDown delay-07s" style={{ paddingLeft: '30px', paddingRight: '30px', background: '#fff', color: 'dimgrey' }}>Lee-Martin Clarke</h1>
            <ul className="we-create animated fadeInUp delay-1s">
              <li style={{ background: '#fff', color: 'dimgrey' }}>Developer and Creator</li>
            </ul>
            <a className="link animated fadeInUp delay-1s" href="https://github.com/leemartinc" target="_blank">Projects</a>
          </div>
          <div>
            <img src={scrollDown} alt="" width={75} height={70} style={{ bottom: '10px', position: 'absolute', transform: 'translateX(-50%)' }} />
            <p style={{ bottom: '-6px', position: 'absolute', left: '50%', transform: 'translateX(-50%)', color: 'white' }}>SCROLL DOWN </p>
          </div>

        </header>
        {/* header-end */}
      </div>
{/* 
      <nav className="main-nav-outer" id="test">
        {/*main-nav-start*
        <div className="container">
          <ul className="main-nav">
            <li>
              <a href="#header">Home</a>
            </li>
            <li>
              <a href="#service">About Me</a>
            </li>
            <li className="small-logo">
              <a href="#header">
                <img
                  className="logo-smaller"
                  src={logo_black}
                  alt=""
                />
              </a>
            </li>
            <li>
              <a href="#client">Skills</a>
            </li>
            <li>
              <a href="#contact">Contact</a>
            </li>
          </ul>
          <a className="res-nav_click" href="#">
            <i className="fa-bars" />
          </a>
        </div>
      </nav> 
      */}

      <>
        <section className="main-section" id="service">
          {/*main-section-start*/}
          <div className="container">
            <h2 style={{ color: "#333" }}>About Me</h2>
            <p className="row center">
              I am confident I am able to bring the skills set required to achieve
              objectives and repeat successes.
            </p>
            <div className="row center">
              <div className="col-lg-4 col-sm-6 wow fadeInLeft delay-05s">
                <div className="service-list">
                  <div className="service-list-col1"></div>
                  <div className="service-list-col2">
                    <h3>Who am i?</h3>
                    <p>
                      I am an experienced developer with skills in front and back end
                      programming as well as computer hardware. These skills, along
                      with experience, help me to provide excellent support in both
                      independent and team environments.{" "}
                    </p>
                  </div>
                </div>
                <div className="service-list">
                  <div className="service-list-col1"></div>
                  <div className="service-list-col2">
                    <p>
                      Additionally, I have a warm and pleasing personality, deep sense
                      of integrity, flexibility, strong organizational and negotiating
                      skills, motivation and initiative which would facilitate a
                      productive and professional atmosphere. I am also a team player
                      who excels in design because of my superb creativity.
                    </p>
                    <p>&nbsp;</p>
                    <div>
                      {/*Alumni info */}
                      <img
                        src={alum}
                        style={{
                          boxShadow:
                            "0 19px 38px rgba(0,0,0,0.20), 0 15px 12px rgba(0,0,0,0.12)"
                        }}
                        width={150}
                        alt=""
                      />
                    </div>
                    <p>&nbsp;</p>
                    {/* Resume Link current available */}
                    <center>
                      <a
                        className="link animated fadeInUp delay-1s"
                        href={resume}
                        download=""
                      >
                        DOWNLOAD MY RESUMÉ
                      </a>
                    </center>
                  </div>
                </div>
                <div className="service-list">
                  <div className="service-list-col1"></div>
                  <div className="service-list-col2"></div>
                </div>
                <div className="service-list">
                  <div className="service-list-col1"></div>
                  <div className="service-list-col2"></div>
                </div>
              </div>
              <span className="wow fadeInUp delay-02s">
                <img
                  src={headshot}
                  style={{
                    boxShadow:
                      "0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)",
                    width: 400,
                    marginTop: 55
                  }}
                  alt=""
                />
              </span>
            </div>
          </div>
        </section>
        {/*main-section-end*/}

        <section className="main-section client-part" id="client">
          {/*main-section client-part-start*/}
          <div className="container">
            <b className="quote-right wow fadeInDown delay-03">
              <i>
                <h>Skills</h>
              </i>
            </b>
            <div className="row">
              <div className="col-lg-12">
                <p className="client-part-haead wow fadeInDown delay-05">
                  Software Development Concentrations
                </p>
              </div>
            </div>
            <ul className="client wow fadeIn delay-05s">
              <li>
                <a>
                  <h3>Web Development</h3>
                  <p>React JS</p>
                  <p>HTML &amp; CSS</p>
                  <p>JavaScript</p>
                  <p>PHP &amp; &amp; NODE JS</p>
                  <br />
                  <h3>Mobile/Application Development</h3>
                  <p>Android Studio</p>
                  <p>Xcode(swift)</p>
                  <p>Java</p>
                  <p>Python</p>
                  <br />
                  <h3>Operating Systems</h3>
                  <p>Command Line</p>
                  <p>Microsoft Windows</p>
                  <p>MacOS</p>
                  <p>Linux</p>
                </a>
              </li>
            </ul>
          </div>
        </section>
        
        <section className="business-talking">
          {/*business-talking-start*/}
          <div className="container">
            <h2>Lets talk business</h2>
          </div>
        </section>

        <div className="container">
          <section className="main-section contact" id="contact">
            <div className="row">
              {/* <ul className="social-link">
                <li className="twitter fl-rt" style={{ padding: 10 }}>
                  <a href="https://twitter.com/LeeMartinClarke" target="_blank">
                    <i className="fa-twitter" />
                  </a>
                </li>
                <li className="facebook fl-rt" style={{ padding: 10 }}>
                  <a href="https://github.com/leemartinc" target="_blank">
                    <i className="fa-github" />
                  </a>
                </li>
                <li className="gplus fl-rt" style={{ padding: 10 }}>
                  <a
                    href="https://plus.google.com/104860398312635532250"
                    target="_blank"
                  >
                    <i className="fa-google-plus" />
                  </a>
                </li>
                <li className="dribbble fl-rt" style={{ padding: 10 }}>
                  <a
                    href="https://www.linkedin.com/in/lee-martin-c-6702668a/"
                    target="_blank"
                  >
                    <i className="fa-linkedin" />
                  </a>
                </li>
              </ul> */}
              <h3 style={{ textAlign: "center" }}>
                Feel free to contact me via email or through social media!
              </h3>
            </div>
            <div className="wow fadeInUp delay-05s">
              <div className="form">
                <form method="post">
                  <input
                    className="input-text"
                    type="text"
                    name="name"
                    placeholder='Your Name *'
                    value={contactName}
                    onChange={(e) => setContactName(e.target.value)}
                    defaultValue="Your Name *"
                    onfocus="if(this.value==this.defaultValue)this.value='';"
                    onblur="if(this.value=='')this.value=this.defaultValue;"
                  />
                  <input
                    className="input-text"
                    placeholder='Your E-mail *'
                    type="text"
                    name="email"
                    value={contactEmail}
                    onChange={(e) => setContactEmail(e.target.value)}
                    onfocus="if(this.value==this.defaultValue)this.value='';"
                    onblur="if(this.value=='')this.value=this.defaultValue;"
                  />
                  <textarea
                    className="input-text text-area"
                    name="message"
                    placeholder='Your Message *'
                    cols={0}
                    rows={0}
                    value={contactMessage}
                    onChange={(e) => setContactMessage(e.target.value)}
                    onfocus="if(this.value==this.defaultValue)this.value='';"
                    onblur="if(this.value=='')this.value=this.defaultValue;"
                  />
                  <div style={{ textAlign: "center" }}>
                    <ReCAPTCHA sitekey="6LcQnUEUAAAAAJxm2FP8foAF7_65c1t8O9wuB7Wy" onChange={handleRecaptchaVerify} />
                    <button className="input-btn" name="submit" type="submit">Send Message</button>
                  </div>
                </form>
              </div>
            </div>
          </section>
        </div>


      </>

      <footer className="footer">
        <div className="container">
          <div className="footer-logo">
            <a href="#">
              <img className="logo-smaller" src={logo} alt="" />
            </a>
          </div>
        </div>
      </footer>


    </div>
  );
}

export default Home;